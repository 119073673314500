<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-2 color-gray-600">
  <!--begin::Toolbar container-->
  <div *ngIf="isSimpleHeader; else simpleHead" id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
    <!--begin::Page title-->
    <div data-kt-swapper="true" data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
      data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_toolbar_container'}"
      class="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 mb-lg-0">
      <!--begin::Title-->
      <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center text-capitalize my-0">{{ title }}</h1>
      <!--end::Title-->
    </div>
    <!--end::Page title-->

    <div class="position-relative w-md-400px me-md-2">
      <i class="fa-duotone fa-magnifying-glass fs-3 text-gray-500 position-absolute top-50 translate-middle ms-6"><span
          class="path1"></span><span class="path2"></span></i>
      <input type="text" (keyup)="searchFilter($event.target.value)"
        (keyup.enter)="searchFilerOnEnter($event.target.value)" [(ngModel)]="filterText"
        [ngModelOptions]="{ standalone: true }" class="form-control form-control-solid ps-10" name="search" value=""
        placeholder="Search">
    </div>

    <!--begin::Action group-->
    <div class="d-flex align-items-center gap-2">
      <!--begin::Action wrapper-->
      <div class="d-flex align-items-center">
        <!--begin::Separartor-->
        <div class="bullet bg-secondary h-35px w-1px mx-5"></div>
        <!--end::Separartor-->

        <!--begin::NoUiSlider-->
        <div class="d-flex align-items-center ps-4">

          <a class="btn btn-sm btn-flex btn-primary fw-bold" (click)="routeToAdd()" *ngIf="isAdd">
            <i class="fa-duotone fa-circle-plus fs-6 text-light me-1 ">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </i>
            Add
          </a>

          <a class="btn btn-sm btn-flex btn-primary fw-bold" (click)="showCards()" *ngIf="showCard && isDesktop">
            <i class="fa-duotone fa-circle-plus fs-6 text-light me-1 ">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </i>
            Add
          </a>

          <a class="btn btn-sm btn-flex btn-primary fw-bold" (click)="routeToAddMultiple()"
            *ngIf="isMultiple && isDesktop">
            <i class="fa-duotone fa-circle-plus fs-6 text-light me-1 ">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </i>
            Multiple
          </a>

          <!--end::NoUiSlider-->

          <!--begin::Separartor-->
          <div class="bullet bg-secondary h-35px w-1px mx-5"></div>
          <!--end::Separartor-->
        </div>
        <!--end::Action wrapper-->

        <!--begin::Action wrapper-->
        <div class="d-flex align-items-center">
          
          <!--begin::Actions-->
          <div class="d-flex">

            <a *ngIf="export" class="dropdown btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
              data-kt-menu-trigger="menu" data-kt-menu-placement="bottom-end">
              <i class="fa-duotone fa-sliders fs-6 text-muted ">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
              <div class="dropdown-content">

                <div class="menu-item">
                  <!--begin:Menu link-->
                  <a (click)="exportToExcel($event)" >
                    <span >Export To Excel</span>
                  </a><!--end:Menu link-->
                </div>

                <div class="menu-item">
                  <!--begin:Menu link-->
                              
                  <a *ngIf="'Pages.Administration.Users.Create' | permission" href="javascript:" >
                    <p-fileUpload
                               #ExcelFileUpload
                               (onError)="onUploadExcelError()"
                               (uploadHandler)="importToExcelOperations($event)"
                               accept=".csv,.xls,.xlsx"
                               auto="auto"
                               chooseLabel="{{ 'Import' | localize }}"
                               customUpload="true"
                               maxFileSize="10000000"
                               name="ExcelFileUpload"
                           >
                           </p-fileUpload>
               </a>
               <!--end:Menu link-->
                </div>
                </div>
            </a>

            <a *ngIf="!advancedFiltersAreShown && filter" (click)="openFilter()">
              <i class="fa-duotone fa-down  fs-5 text-muted">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>

            <a *ngIf="advancedFiltersAreShown && filter" (click)="closeFilter()">
              <i class="fa-duotone fa-up  fs-5 text-muted">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>

            <!--begin::Action-->
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Action wrapper-->
      </div>
      <!--end::Action group-->
    </div>
    <!--end::Toolbar container-->
  </div>

<ng-template #simpleHead>
    <!-- new added code responsive header -->

    <div [hidden]="isSimpleHeader" id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div data-kt-swapper="true" data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
        data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_toolbar_container'}"
        class="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 mb-lg-0">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center text-capitalize my-0">  {{ description }}</h1>
        <!--end::Title-->
      </div>
      <!--end::Page title-->

      <div class="d-flex align-items-center gap-2">
        <!--begin::Action wrapper-->
        <div class="d-flex align-items-center">
          <!--begin::Separartor-->
          <div class="bullet bg-secondary h-35px w-1px mx-5"></div>
          <!--end::Separartor-->
  
          <!--begin::NoUiSlider-->
          <div class="d-flex align-items-center ps-4">  
            <div>
              <ng-content select="div[role=actions]"></ng-content>
          </div>
          
          </div>
       
        </div>
       
      </div>
      <!--end::Toolbar container-->
    </div>
</ng-template>

  <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>